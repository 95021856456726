<template>
  <div id="app">
    <h1>BIOPREVA</h1>
    <semipolar-spinner
      v-if="state == 0"
      :animation-duration="3000"
      :size="65"
      color="#3e94f0"
      class="center"
    />
    <p v-else-if="state == 2">
      {{ $t("invalid") }}<br /><br />
      {{ $t("redirect") }}
      <a href="https://biopreva.com">biopreva.com</a>. <br /><br />
      <hollow-dots-spinner
        :animation-duration="2000"
        :dot-size="15"
        :dots-num="3"
        color="#3e94f0"
        style="margin: auto"
      />
    </p>
    <p v-else-if="state == 3">
      {{ $t("success") }}<br /><br />
      {{ $t("redirect") }}
      <a href="https://biopreva.com">biopreva.com</a>. <br /><br />
      <hollow-dots-spinner
        :animation-duration="2000"
        :dot-size="15"
        :dots-num="3"
        color="#3e94f0"
        style="margin: auto"
      />
    </p>
    <p v-else>
      {{ $t("wrong") }}<br /><br />
      {{ $t("redirect") }}
      <a href="https://biopreva.com">biopreva.com</a>. <br /><br />
      <hollow-dots-spinner
        :animation-duration="2000"
        :dot-size="15"
        :dots-num="3"
        color="#3e94f0"
        style="margin: auto"
      />
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { SemipolarSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  name: "App",
  components: {
    SemipolarSpinner,
    HollowDotsSpinner,
  },
  data: function () {
    return {
      state: 0,
    };
  },
  mounted() {
    if (this.$route.query.lang == "fr" || this.$route.query.lang == "en") {
      this.$i18n.locale = this.$route.query.lang;
    }
    if (this.$route.query.code == undefined) {
      this.state = 1;
      this.redirect();
    } else {
      axios
        .put("https://api.biopreva.com/verify-email", {
          code: this.$route.query.code,
        })
        .then(() => {
          this.state = 3;
          this.redirect();
        })
        .catch(() => {
          this.state = 2;
          this.redirect();
        });
    }
  },
  methods: {
    redirect() {
      setTimeout(function () {
        window.location.href = "https://biopreva.com";
      }, 5000);
    },
  },
};
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3e94f0;
}

h1 {
  padding-top: 50px;
  font-size: 50px;
}

.center {
  left: 50vw;
  transform: translate(-50%);
}

a {
  color: #3151f1;
}
</style>
